<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 관리부서 -->
          <c-dept
            :editable="editable"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 단위공정 -->
          <c-process
            :editable="editable"
            label="LBL0001701"
            name="subProcessCd"
            v-model="searchParam.subProcessCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 설비유형 -->
          <c-equip-class
            :editable="editable"
            label="LBL0001720"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 설비등급 -->
          <c-select
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="LBL0003280"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 폐기여부 -->
          <c-select
            :comboItems="discardFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="discardFlag"
            label="LBL0001723"
            v-model="searchParam.discardFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 설비 목록 -->
        <c-table
          ref="table"
          title="LBL0001726"
          tableId="equipmentTable"
          selection="multiple"
          rowKey="equipmentCd"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- QR코드 출력 -->
              <c-btn label="LBL0003281" :editable="editable" icon="save_alt" @btnClicked="btnQrCode" />
              <!-- 등록 -->
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-manager',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        subProcessCd: null,
        equipmentName: '',
        equipmentTypeCd: '',
        grade: null,
        discardFlag: 'N'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      discardFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 설비관리번호
            label: 'LBL0001727',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0001725',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0001720',
            align: 'center',
            sortable: true,
            style: 'width: 250px',
          },
          {
            name: 'processName',
            field: 'processName',
            // 단위공정
            label: 'LBL0001701',
            align: 'center',
            sortable: true,
            style: 'width: 200px',
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            // 관리부서
            label: 'LBL0001721',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipmentLocation',
            field: 'equipmentLocation',
            // 설비위치
            label: 'LBL0003282',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipGradeName',
            field: 'equipGradeName',
            // 설비등급
            label: 'LBL0003280',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'discardFlag',
            field: 'discardFlag',
            // 폐기여부
            label: 'LBL0001723',
            align: 'center',
            sortable: true,
            style: 'width: 70px',
          },
        ],
        data: [],
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.equipment.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = 'LBL0001728'; // 설비 상세
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
        psmFlag: row ? row.psmFlag : '',
      };
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    btnQrCode() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // '안내',
          message: 'MSG0001006', // '설비 목록 중 선택된 항목이 없습니다.',
          type: 'warning',
        });
        return;
      }
      if (selectData.length > 10) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // '안내',
          message: 'MSG0001007', // 'QR코드 일괄출력은 한번에 10개까지 가능합니다.',
          type: 'warning',
        });
        return;
      }
      this.popupOptions.title = "LBL0003283";  // 설비 QR코드
      this.popupOptions.param = {
        selectData: selectData,
      };
      this.popupOptions.target = () => import(`${"./equipmentQr.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '800px';
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>
